var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Select purchases","show":_vm.show,"size":"lg"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex w-100"},[_c('TMessage',{attrs:{"content":"Selected: "},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(_vm._s(_vm.ordersSelected.length))]},proxy:true}])}),_c('TButton',{staticClass:"ml-auto",attrs:{"options":{ disabled: _vm.lodash.isEmpty(_vm.ordersSelected) },"variant":"outline","content":"Select","icon":"cil-check"},on:{"click":_vm.submitOrdersSelected}})],1)]},proxy:true}])},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"order.purchase_payable","resource":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 py-1 ml-1 text-center my-auto",attrs:{"col":"12"}},[_c('TInputCheckbox',{attrs:{"checked":_vm.lodash.findIndex(_vm.ordersSelected, ['id', item.id]) !== -1},on:{"change":function($event){return _vm.selectOrder(item)}}})],1)]}},{key:"supplier",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{staticClass:"text-nowrap",attrs:{"content":item.supplier.name,"noTranslate":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.outstanding,"currency":item.currency_id}})],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.paid,"currency":item.currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id,"color":"muted"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }